import { shopMessages } from '@/services/firebase';
import { computed, ComputedRef } from 'vue';
import MessagesService from '@/services/messages/MessagesService';
import { FbMessageI } from '@/services/firebase/FirebaseService';

function hqMessagesConversation() {
    return computed((): FbMessageI | null => {
        if (
            shopMessages?.value?.hq &&
            typeof shopMessages.value.hq === 'object'
        ) {
            return Object.values(shopMessages.value.hq)[0];
        }
        return null;
    });
}

function orderMessagesConversation(refNum?: string) {
    return computed((): FbMessageI | null => {
        if (
            refNum &&
            shopMessages?.value?.orders &&
            shopMessages?.value?.orders[refNum]
        ) {
            return shopMessages.value.orders[refNum];
        }
        return null;
    });
}

export const hasHqMessages = computed((): boolean => {
    const conversationInfo = hqMessagesConversation();
    return Boolean(conversationInfo?.value?.hasNewMessage);
});

export const hqMessages = () => {
    const conversationInfo = hqMessagesConversation();
    return {
        conversationInfo,
        ...MessagesService(conversationInfo, 'notifications'),
    };
};

export const hasOrderMessages = (refNum?: string): ComputedRef<boolean> =>
    computed((): boolean => {
        const conversationInfo = orderMessagesConversation(refNum);
        return !!conversationInfo.value && conversationInfo.value.hasNewMessage;
    });

export const orderMessages = (refNum?: string) => {
    const conversationInfo = orderMessagesConversation(refNum);

    return {
        conversationInfo,
        ...MessagesService(conversationInfo, 'conversation'),
    };
};
