import { computed, ComputedRef, inject, Ref, ref, watch } from 'vue';
import parseDate from 'date-fns/parseJSON';
import { FbMessageI } from '@/services/firebase/FirebaseService';
import { ApiService } from '@/services/api';
import { Store } from '@/stores';
import { StateCache } from '@/services/cache';

const client = new ApiService();
const state = new StateCache

export interface ComputedMessageData {
    conversationId: ComputedRef<string | null>;
    conversationTimestamp: ComputedRef<string | null>;
    messages: Ref<ChatMessageI[]>;
    groupedMessages: ComputedRef<GroupedChatMessagesI[]>;
    firstNewGroup: ComputedRef<GroupedChatMessagesI | null>;
    // sendMessage(text: string, orderID: number): void;
    sendCustomerMessage(text: string, orderID: number, referenceNumber: string): Promise<void>;
    sendHqMessage(text: string): Promise<void>;
    escalateToGX(order_reference_number: number): void;
    markConvoRead(order_reference_number: string | undefined): Promise<ApiRes.ApiResBase>;
    markNotificationRead(): Promise<ApiRes.ApiResBase>;
    refreshMessages(): void;
}

export default function useMessageData(
    conversation: ComputedRef<FbMessageI | null>,
    messageType: string
): ComputedMessageData {
    const store = inject('store') as Store;

    /**
     * The Kustomer ID for the current conversation
     */
    const conversationId = computed((): string | null => {
        return conversation.value?.threadId ?? null;
    });

    /**
     * Last-received message timestamp
     */
    const conversationTimestamp = computed((): string | null => {
        return conversation.value?.time ?? null;
    });

    /**
     * Get an array of messages in the current conversation
     */
    const messages = ref([] as ChatMessageI[]);
    const refreshMessages = (): void => {
        if (!conversationId.value || !messageType) {
            messages.value = [] as ChatMessageI[];
            return;
        }

        client
            .get(`/curbside/${messageType}/${conversationId.value}`)
            .then(response => response.data ?? response)
            .then(data => {
                if (!data || !data.success || !data.response) {
                    throw new Error('No response from server.');
                }
                messages.value = data.response as ChatMessageI[];
            })
            .catch(() => {
                messages.value = [] as ChatMessageI[];
            });
    };
    refreshMessages();

    /**
     * Update the message data whenever the most-recent timestamp changes
     */
    watch([conversationTimestamp, conversationId], refreshMessages);

    /**
     * Group the messages into 5-minute batches
     */
    const groupedMessages = computed((): GroupedChatMessagesI[] => {
        const today = new Date();
        const yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24);

        if (!messages.value) {
            return [] as GroupedChatMessagesI[];
        }

        // Group the messages into batches of no more than 5 minutes
        return messages.value.reduce(
            (
                groups: GroupedChatMessagesI[],
                message: ChatMessageI
            ): GroupedChatMessagesI[] => {
                const lastGroup = groups[groups.length - 1];
                const timestamp = parseDate(message?.attributes?.createdAt ?? '').getTime();
                const minutesSinceLast = lastGroup ? (timestamp - lastGroup.time) / 1000 / 60 : 999;

                if (lastGroup && minutesSinceLast < 5) {
                    lastGroup.messages.push(message);
                } else {
                    const dateObject = new Date(timestamp);
                    const formattedTime = dateObject.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                    });
                    let formattedDate = dateObject.toLocaleDateString([], {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                    });
                    if (dateObject.toDateString() === today.toDateString()) {
                        formattedDate = 'Today';
                    } else if (
                        dateObject.toDateString() === yesterday.toDateString()
                    ) {
                        formattedDate = 'Yesterday';
                    }

                    groups.push({
                        direction: message?.attributes?.direction ?? '',
                        time: timestamp,
                        formattedTime,
                        formattedDate,
                        messages: [message],
                    } as GroupedChatMessagesI);
                }

                return groups;
            },
            [] as GroupedChatMessagesI[]
        );
    });

    const firstNewGroup = computed((): GroupedChatMessagesI | null => {
        // "new" groups are any within the last 10 minutes
        const cutoff = store.state.currentTime.getTime() - 10 * 60 * 1000;

        return groupedMessages.value
            ? (groupedMessages.value.find(group => group.time > cutoff) as GroupedChatMessagesI)
            : null;
    });

    const markConvoRead = (order_reference_number: string | undefined) => {
        if (!conversationId.value) return Promise.reject('No ThreadID avalible');
        if (!order_reference_number) return Promise.reject('No order_reference_number passed');
        return client.post(`/curbside/conversation/${conversationId.value}/read`, {order_reference_number});
    }

    const markNotificationRead = () => {
        if (!conversationId.value) return Promise.reject('No ThreadID avalible');
        return client.post(`/curbside/notifications/${conversationId.value}/read`);
    }

    const sendCustomerMessage = async (text: string, orderID: number, referenceNumber: string): Promise<void> => {
        const parms: any = { message: text.trim() };
        if (orderID) parms.order_id = orderID;
        if (conversationId.value) parms.thread_id = conversationId.value
        // Mark convo as read when store responds to customer
        try {
            await markConvoRead(referenceNumber);
        } catch (error) {
            console.info(error);
        }

        client.post(`/curbside/conversation`, parms);
    };

    const sendHqMessage = async (text: string): Promise<void> => {
        console.log('sendHqMessage', conversationId.value, messageType);
        const parms: any = {
            message: text.trim(),
            shop_id: (await state.get(state.SHOP_ID)),
        };
        // add thread_id if avalible
        if (conversationId.value) parms.thread_id = conversationId.value
        try {
            await markNotificationRead();
        } catch (error) {
            console.info(error);
        }
        // Mark convo as read when store responds to customer
        client.post(`/curbside/notifications`, parms);
    };

    const escalateToGX = (order_reference_number: number): void => {
        const thread_id = conversationId.value;
        client.post(`/curbside/conversation/${thread_id}/move`, { thread_id, order_reference_number });
    };

    return {
        conversationId,
        conversationTimestamp,
        messages,
        groupedMessages,
        firstNewGroup,
        sendCustomerMessage,
        sendHqMessage,
        escalateToGX,
        markConvoRead,
        markNotificationRead,
        refreshMessages,
    };
}

export interface ChatMessageI {
    type: string;
    id: string;
    attributes: ChatMessageAttributesI;
    relationships: {
        [key: string]: any;
    };
    links: {
        [key: string]: any;
    };
}

export interface ChatMessageAttributesI {
    externalId?: string;
    channel: string;
    app: string;
    size: number;
    direction: 'in' | 'out';
    directionType: string;
    location?: {
        countryName: string;
        cityName: string;
        regionName: string;
        zipCode: string;
    };
    preview: string;
    meta: {
        [key: string]: any;
    };
    status: string;
    assignedTeams: [];
    assignedUsers: [];
    auto: boolean;
    sentAt: string; //2020-10-16T18:59:46.123Z
    createdAt: string; //2020-10-16T18:59:46.123Z
    updatedAt: string; //2020-10-16T18:59:46.123Z
    modifiedAt?: string; //2020-10-16T18:59:46.123Z
    redacted: boolean;
    createdByTeams: [];
    rev: number;
    reactions: [];
}

export interface GroupedChatMessagesI {
    direction: 'in' | 'out';
    time: number;
    formattedTime: string;
    messages: ChatMessageI[];
}
