import FirebaseService, { FbMessageI, FbMessagesI, FbOrderTypeCollectionI } from './FirebaseService';
import { ref, Ref } from 'vue';
import { StateCache } from '@/services/cache';

const fbService = new FirebaseService();
const cache = new StateCache();

const shopOrders: Ref<FbOrderTypeCollectionI> = ref({
    curbsideOrders: [],
    pickupOrders: [],
    oldOrders: [],
    pendingNonDeliveryOrders: [],
    all: [],
});
cache
    .get(cache.SHOP_ID)
    .then((shopID: string) =>
        fbService.watchShop( shopID, fbService.flattenOrders.bind(fbService, shopOrders)
    ));

const shopMessages: Ref<FbMessagesI> = ref({});
cache
    .get(cache.SHOP_ID)
    .then((shopID: string) =>
        fbService.watchMessages( shopID, fbService.flattenMessages.bind(fbService, shopMessages)
    ));


const unreadShopMessages: Ref<FbMessageI[]> = ref([]);
cache
    .get(cache.SHOP_ID)
    .then((shopID: string) =>
        fbService.watchMessages( shopID, fbService.flattenUnreadMessages.bind(fbService, unreadShopMessages)
    ));

export { shopOrders };
export { shopMessages };
export { unreadShopMessages };
