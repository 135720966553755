import config, { FireBaseConfigI } from '@/services/config';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { Ref } from 'vue';
import { ApiService } from '@/services/api';
import { showAuthError } from '@/composables/Alert/useAlert';
import parseDate from 'date-fns/parse';
import addDate from 'date-fns/add';
import isTodayDate from 'date-fns/isToday';

const api = new ApiService();

export default class FirebaseService {
    // TODO leave blank, fetch from api
    protected token!: string;
    protected get configs(): FireBaseConfigI {
        const { firebaseConfig } = config();
        return firebaseConfig();
    }

    protected get databasePrefix(): string {
        const { firebaseDatabasePrefix } = config();
        return firebaseDatabasePrefix();
    }

    protected get messagingDatabasePrefix(): string {
        const { firebaseMessagingDatabasePrefix } = config();
        return firebaseMessagingDatabasePrefix();
    }

    protected _firebase = firebase.initializeApp(this.configs);

    watchShop(
        shopID: number | string,
        cb: (a: firebase.database.DataSnapshot, b?: string | null | undefined ) => any
    ): void {
        this.auth()
            .then(() => this.ref(`/${shopID}/orders`).on('value', cb))
            .catch(showAuthError);
    }

    watchMessages(
        shopID: number | string,
        cb: ( a: firebase.database.DataSnapshot, b?: string | null | undefined ) => any
    ): void {
        this.auth()
            .then(() => this.ref(`/${shopID}`, this.messagingDatabasePrefix).on( 'value', cb))
            .catch(showAuthError);
    }

    async flattenOrders(reactiveVal: Ref<FbOrderTypeCollectionI>, snapShot: firebase.database.DataSnapshot ) {
        const orders: FbOrderI[] = [];
        snapShot.forEach(this.forEachOrders.bind(this, orders));
        reactiveVal.value = orders.reduce(this.reduceOrdersIntoTypes.bind(this), {
            curbsideOrders: [],
            pickupOrders: [],
            oldOrders: [],
            pendingNonDeliveryOrders: [],
            all: [],
        });
        reactiveVal.value.curbsideOrders = reactiveVal.value.curbsideOrders.sort(this.sortByPickUpTime.bind(this));
        reactiveVal.value.pickupOrders = reactiveVal.value.pickupOrders.sort(this.sortByPickUpTime.bind(this));
        reactiveVal.value.oldOrders = reactiveVal.value.oldOrders.sort(this.sortByAcknowledgeHandoff.bind(this));
        reactiveVal.value.pendingNonDeliveryOrders = reactiveVal.value.pendingNonDeliveryOrders.sort(this.sortByPickUpTime.bind(this));

    }

    protected forEachOrders(
        orders: FbOrderI[],
        orderSnap: firebase.database.DataSnapshot
    ) {
        orders.push(orderSnap.val());
    }

    protected reduceOrdersIntoTypes(collections: FbOrderTypeCollectionI, order: FbOrderI): FbOrderTypeCollectionI {
        const pickupTimeDate = parseDate(order.pickupTime, 'yyyy-MM-dd kk:mm:ss', new Date());
        const orderNotForToday = !isTodayDate(pickupTimeDate);
        const fourHoursFromNow = addDate(new Date(), {hours: 4});
        const pickupIsForNextDay = pickupTimeDate.getTime() > fourHoursFromNow.getTime();

        if ( orderNotForToday && pickupIsForNextDay ) return collections;

        collections.all.push(order);
        if (order?.orderDetails?.orderMethod == 'delivery') {
            return this.handleDeliveryOrder(collections, order);
        } else {
            return this.handlePickupOrder(collections, order);
        }
    }

    protected handleDeliveryOrder(collections: FbOrderTypeCollectionI, order: FbOrderI): FbOrderTypeCollectionI {
        const handedOff = typeof order?.orderDetails.acknowledge_handoff !== 'undefined';
        const completeDeliveryOrder = order?.orderDetails.orderMethod == 'delivery' && order?.orderDetails.status === 'delivered';
        const isOldOrder: boolean = handedOff && this.isBeforeCutoffTime(order)
        const isQueued = order?.orderDetails.status === 'queued';

        if (isQueued) {
            collections.pendingNonDeliveryOrders.push(order);
        } else if (completeDeliveryOrder || isOldOrder) {
            collections.oldOrders.push(order);
        } else if(['processing', 'ready-for-delivery', 'out-for-delivery'].indexOf(order?.orderDetails?.status) > -1 && !handedOff) {
            collections.pickupOrders.push(order);
        }
        return collections;
    }

    protected handlePickupOrder(collections: FbOrderTypeCollectionI, order: FbOrderI): FbOrderTypeCollectionI {
        const handedOff = order?.orderDetails.status === 'picked-up' || typeof order?.orderDetails.acknowledge_handoff !== 'undefined';
        const isQueued = order?.orderDetails.status === 'queued';
        const vehicleIsDefined = Boolean(order?.orderDetails.arrival_status?.vehicle);

        const isPickupOrders = !handedOff && !vehicleIsDefined && !isQueued ;
        const isCurbsideOrders = !handedOff  && vehicleIsDefined && !isQueued ;

        const isHandedOff = order?.orderDetails.status === 'picked-up' || typeof order?.orderDetails.acknowledge_handoff !== 'undefined';
        const isOldOrder = handedOff && this.isBeforeCutoffTime(order);
        const isFutureOrder = isQueued && !(isHandedOff);

        if (isCurbsideOrders) {
            collections.curbsideOrders.push(order);
        } else if(isPickupOrders) {
            collections.pickupOrders.push(order);
        } else if(isOldOrder) {
            collections.oldOrders.push(order);
        } else if(isFutureOrder) {
            collections.pendingNonDeliveryOrders.push(order);
        }
        return collections;
    }

    protected isBeforeCutoffTime(order: FbOrderI) {
        const minutesToShowOrdersAfterPickup = 5;
        const cutoffTime = new Date().getTime() - minutesToShowOrdersAfterPickup * 60 * 1000;
        const handoffTime = parseDate(order?.orderDetails?.acknowledge_handoff, 'yyyy-MM-dd kk:mm:ss', new Date() ).getTime();
        return handoffTime > cutoffTime;
    }

    protected sortByPickUpTime(a: FbOrderI, b: FbOrderI) {
        const aTime = parseDate(a.pickupTime, 'yyyy-MM-dd kk:mm:ss', new Date() ).getTime()
        const bTime = parseDate(b.pickupTime, 'yyyy-MM-dd kk:mm:ss', new Date() ).getTime()
        return aTime - bTime;
    }

    protected sortByAcknowledgeHandoff(a: FbOrderI, b: FbOrderI) {
        const bTime = parseDate(b.orderDetails?.acknowledge_handoff ?? b.pickupTime, 'yyyy-MM-dd kk:mm:ss', new Date() ).getTime()
        const aTime = parseDate(a.orderDetails?.acknowledge_handoff ?? a.pickupTime, 'yyyy-MM-dd kk:mm:ss', new Date() ).getTime()
        return bTime - aTime;
    }

    flattenMessages(
        reactiveVal: Ref<FbMessagesI>,
        snapShot: firebase.database.DataSnapshot
    ) {
        const messages: FbMessagesI = {
            hq: snapShot.child('hq').val(),
            orders: snapShot.child('orders').val(),
        };
        reactiveVal.value = messages;
    }

    flattenUnreadMessages(
        reactiveVal: Ref<FbMessageI[]>,
        snapShot: firebase.database.DataSnapshot
    ) {
        reactiveVal.value = [];
        snapShot.child('orders').forEach( orderSnap => {
            const order = orderSnap.val();
            order.orderNumber = orderSnap.key;
            if (order.hasNewMessage) {
                reactiveVal.value.push(order);
            }
        });
    }

    protected ref(path = '', prefix = this.databasePrefix ): firebase.database.Reference {
        return this._firebase.database().ref(`/${prefix}${path}`);
    }

    /**
     * this method must be ran before all
     */
    protected async auth(): Promise<void> {
        // already authenticated, skip this

        if (!this.token) {
            // todo get token from api
            const res = await api.getFirebaseToken();
            this.token = res.data.token;
        }
        try {
            await this._firebase.auth().signInWithCustomToken(this.token);
        } catch (error) {
            console.error(error);
        }
        return;
    }
}

export interface FbOrderI {
    /**Datetime: 2020-10-16 18:59:46 */
    chitsPrintTime: string;
    /**Datetime: 2020-10-16 18:59:46 */
    labelsPrintTime: string;
    orderDetails: FbOrderOrderDetailsI;
    /**Datetime: 2020-10-16 18:59:46 */
    pickupTime: string;
    pies: FbOrderPieI[];
    extras: FbOrderExtraI[];
    tagline: string;
    /** When defined, it means order is delivery type */
    deliveryInfo: {
        delivery_time: {
            max: string;
            min: string;
        };
        service: string;
        status: string;
    };
}

export interface FbOrderOrderDetailsI {
    arrival_status: FbOrderOrderDetailsOrderStatusI;
    acknowledge_arrival: string; // Datetime: 2020-10-16 18:59:46
    acknowledge_handoff: string; // Datetime: 2020-10-16 18:59:46
    can_confirm_arrival_timestamp: string; // Datetime: 2020-10-16 18:59:46
    client_platform: string;
    customerFirstName: string;
    customerLastName: string;
    line_number: number;
    notifications: number;
    orderID: number;
    orderKey: string;
    orderNumber: string;
    orderNumberRaw: string;
    pickupTime: string; // Datetime: 2020-10-16 18:59:46
    refired: boolean;
    status: 'received'
        | 'queued'
        | 'processing'
        | 'complete'
        | 'picked-up'
        | 'cancelled'
        | 'delivered'
        | 'ready-for-delivery'
        | 'out-for-delivery';
    totalPies: number;
    vendor_name: string;
    orderMethod: string;
}

export interface FbOrderOrderDetailsOrderStatusI {
    arrived_at: string; // Datetime: 2020-10-16 18:59:46
    can_confirm_arrival: boolean;
    details: string;
    vehicle: FbOrderOrderDetailsOrderStatusCurbsideVehicleI;
}

export interface FbOrderOrderDetailsOrderStatusCurbsideVehicleI {
    color: string;
    make: string;
    type: string;
}

export interface FbOrderPieI {
    chitPrinted: boolean;
    comment: string;
    hitIngredients: string[];
    ingredients: FbOrderPieIngredientI[];
    labelPrinted: boolean;
    modifiedHit: boolean;
    name: string;
    pieOfOrder: number;
    recipientName: string;
}

export interface FbOrderPieIngredientI {
    groupName: string;
    items: FbOrderPieIngredientItemI[];
}

export interface FbOrderPieIngredientItemI {
    important: boolean;
    name: string;
}

export interface FbOrderExtraI {
    chitPrinted: boolean;
    labelPrinted: boolean;
    name: string;
    quantity: number;
    modifiers: FbOrderExtraModifierI[];
}

export interface FbOrderExtraModifierI {
    description: string;
}

export interface FbMessagesI {
    hq?: FbHqMessagesI;
    orders?: FbOrderMessagesI;
}

export interface FbHqMessagesI {
    [key: string]: FbMessageI;
}

export interface FbOrderMessagesI {
    [key: string]: FbMessageI;
}

export interface FbMessageI {
    hasNewMessage: boolean;
    /**Datetime: 2020-10-16 18:59:46 */
    time: string;
    threadId: string;
    isEscalated: boolean;
    orderNumber?: string;
}

export interface FbOrderTypeCollectionI {
    curbsideOrders: FbOrderI[];
    pickupOrders: FbOrderI[];
    oldOrders: FbOrderI[];
    pendingNonDeliveryOrders: FbOrderI[];
    all: FbOrderI[];
}
